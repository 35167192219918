import { render, staticRenderFns } from "./LocalityList.vue?vue&type=template&id=093e4481&scoped=true&"
import script from "./LocalityList.vue?vue&type=script&lang=js&"
export * from "./LocalityList.vue?vue&type=script&lang=js&"
import style0 from "./LocalityList.vue?vue&type=style&index=0&id=093e4481&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "093e4481",
  null
  
)

export default component.exports