<template>
  <div>
    <v-btn
      :disabled="disableCreateButton"
      small
      class="my-2"
      color="success"
      @click.stop="openCreateDialog()"
    >
      {{ $t("btnCreate") }}
    </v-btn>
    <v-btn
      :disabled="disableCreateObligatoryButton"
      small
      class="my-2 ml-2"
      color="success"
      @click.stop="openCreateObligatoryDialog()"
      >{{ $t("PriceExtra.btnCreateObligatory") }}
    </v-btn>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      sort-by.sync="name"
      sort-desc.sync="true"
      class="elevation-1"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, 250, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.driverSummary`]="{ item }">
        <v-simple-checkbox
          v-model="item.driverSummary"
          disabled
        ></v-simple-checkbox>
      </template>
      <!-- template na operation zo zoznamu enums $t('PriceExtraEnums.OperationArray') -->
      <template v-slot:[`item.operation`]="{ item }">
        {{ $t("PriceExtraEnums.Operation." + item.operation) }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ $t("PriceExtraEnums.Type." + item.type) }}
      </template>
      <template v-slot:[`item.automatic`]="{ item }">
        <v-simple-checkbox
          v-model="item.automatic"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:[`item.editable`]="{ item }">
        <v-simple-checkbox v-model="item.editable" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.cancelPriceFixation`]="{ item }">
        <v-simple-checkbox
          v-model="item.cancelPriceFixation"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:[`item.resetToDefaultPrice`]="{ item }">
        <v-simple-checkbox
          v-model="item.resetToDefaultPrice"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" small @click="getAll()">{{
          $t("btnReload")
        }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.type == 'PRICE_JOURNEY' || item.type == 'PRICE_WAITING'"
          small
          @click="openEditObligatoryDialog(item)"
        >
          mdi-square-edit-outline
        </v-icon>
        <v-icon v-else small @click="openEditDialog(item)">
          mdi-square-edit-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="editDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("PriceExtra.priceExtraDialogTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('PriceExtra.name')"
                    filled
                    v-model="editItem.name"
                    required
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="editItem.driverSummary"
                    :label="$t('PriceExtra.driverSummary')"
                    required
                    dense
                    filled
                    :disabled="editItem.automatic"
                    @change="
                      (editItem.editable = true),
                        (editItem.resetToDefaultPrice = false),
                        (editItem.cancelPriceFixation = false)
                    "
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="editItem.automatic"
                    :label="$t('PriceExtra.automatic')"
                    required
                    dense
                    filled
                    :disabled="editItem.driverSummary"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="editItem.active"
                    :label="$t('PriceExtra.active')"
                    required
                    filled
                    dense
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <!-- :items="Object.keys(Type)" -->
                  <v-select
                    :label="$t('PriceExtra.type')"
                    filled
                    v-model="editItem.type"
                    :items="$t('PriceExtraEnums.TypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                    dense
                    @change="
                      editItem.type == 'WAITING' &&
                      editItem.operation != 'WAITING_TIME' &&
                      editItem.operation != 'WAITING_TIME_EXACT'
                        ? (editItem.operation = 'WAITING_TIME')
                        : ''
                    "
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PriceExtra.price')"
                    filled
                    v-model="editItem.price"
                    required
                    dense
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <!-- :items="Object.keys(Operation)" -->
                  <v-select
                    :label="$t('PriceExtra.operation')"
                    filled
                    v-model="editItem.operation"
                    :items="$t('PriceExtraEnums.OperationArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    :label="$t('PriceExtra.resetToDefaultPrice')"
                    v-model="editItem.resetToDefaultPrice"
                    required
                    dense
                    filled
                    :disabled="
                      editItem.driverSummary || pricelistType == 'SIMPLE'
                    "
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    :label="$t('PriceExtra.cancelPriceFixation')"
                    v-model="editItem.cancelPriceFixation"
                    required
                    dense
                    filled
                    :disabled="editItem.driverSummary"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    :label="$t('PriceExtra.editable')"
                    v-model="editItem.editable"
                    required
                    dense
                    filled
                    :disabled="editItem.driverSummary"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PriceExtra.occurenceLimit')"
                    filled
                    v-model="editItem.occurenceLimit"
                    required
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PriceExtra.ordering')"
                    filled
                    dense
                    v-model="editItem.ordering"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="editDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn
              color="success"
              :loading="loadingSaveItem"
              text
              @click.stop="submitEditDialog()"
              >{{ $t("btnSave") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editObligatoryDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("PriceExtra.priceExtraObligatoryDialogTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreateObligatory">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('PriceExtra.name')"
                    filled
                    v-model="editItem.name"
                    required
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <!-- :items="obligatoryTypeEnum" -->
                  <v-select
                    :label="$t('PriceExtra.type')"
                    filled
                    v-model="editItem.type"
                    :items="$t('PriceExtraEnums.ObligatoryTypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                    dense
                    :disabled="disabledObligatoryFields"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    :label="$t('PriceExtra.editable')"
                    v-model="editItem.editable"
                    required
                    dense
                    filled
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click.stop="editObligatoryDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
            <v-btn
              color="success"
              :loading="loadingSaveItem"
              text
              @click.stop="submitEditObligatoryDialog()"
              >{{ $t("btnSave") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {
  Type,
  Operation,
  Automatic,
  Unit,
  ObligatoryType,
} from "../enums/PriceExtraEnum";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  name: "PriceExtraList",
  props: {
    pricelistId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      Type,
      Operation,
      Automatic,
      Unit,
      ObligatoryType,
      obligatoryTypeEnum: ["PRICE_WAITING", "PRICE_JOURNEY"],

      //Nastavenia modulov
      module: moduleDefaultSettings,
      pricelistType: "SIMPLE",

      gridHeaders: [
        {
          text: this.$t("PriceExtra.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        { text: this.$t("PriceExtra.tableHeaders.active"), value: "active" },
        { text: this.$t("PriceExtra.tableHeaders.name"), value: "name" },
        { text: this.$t("PriceExtra.tableHeaders.type"), value: "type" },
        { text: this.$t("PriceExtra.tableHeaders.price"), value: "price" },
        {
          text: this.$t("PriceExtra.tableHeaders.resetToDefaultPrice"),
          value: "resetToDefaultPrice",
        },
        {
          text: this.$t("PriceExtra.tableHeaders.cancelPriceFixation"),
          value: "cancelPriceFixation",
        },
        {
          text: this.$t("PriceExtra.tableHeaders.operation"),
          value: "operation",
        },
        {
          text: this.$t("PriceExtra.tableHeaders.automatic"),
          value: "automatic",
        },
        {
          text: this.$t("PriceExtra.tableHeaders.driverSummary"),
          value: "driverSummary",
        },
        {
          text: this.$t("PriceExtra.tableHeaders.editable"),
          value: "editable",
        },
        {
          text: this.$t("PriceExtra.tableHeaders.occurenceLimit"),
          value: "occurenceLimit",
        },
      ],
      gridItems: [],

      loadingData: false,
      loadingSaveItem: false,

      editDefaultItem: {
        id: null,
        active: false,
        name: "",
        resetToDefaultPrice: false,
        cancelPriceFixation: false,
        price: 0.0,
        type: null,
        operation: null,
        automatic: false,
        driverSummary: true,
        occurenceLimit: 1,
        editable: false,
        ordering: 1,
      },
      editItem: {},
      editDialog: false,
      //zablokovane, ak nie su vytvorene povinne polozky
      disableCreateButton: false,
      disableCreateObligatoryButton: false,

      editObligatoryDialog: false,
      disabledObligatoryFields: false,
      editObligatoryItem: {
        id: null,
        active: true,
        name: "",
        resetToDefaultPrice: false,
        cancelPriceFixation: false,
        price: 0.0,
        type: null,
        operation: "NONE",
        automatic: false,
        driverSummary: false,
        occurenceLimit: 1,
        editable: false,
        ordering: 1,
      },

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
      this.pricelistType = modules.pricelistType;
    }
  },

  mounted() {
    this.enumsValidation();
    this.getAll();
  },

  methods: {
    enumsValidation() {
      //check if obligatoryType contains same items as $t('PriceExtraEnums.ObligatoryTypeArray')
      var ObligatoryType = this.ObligatoryType;
      var ObligatoryTypeArray = this.$t("PriceExtraEnums.ObligatoryTypeArray");
      if (ObligatoryTypeArray.length !== Object.keys(ObligatoryType).length) {
        console.error(
          "ObligatoryTypeArray is not same length as ObligatoryType enum"
        );
      }
      ObligatoryTypeArray.forEach((element) => {
        if (element.value != ObligatoryType[element.value]) {
          console.error(
            "ObligatoryTypeArray contains item that is not in ObligatoryType enum " +
              element.value
          );
        }
      });

      //check if Type contains same items as $t('PriceExtraEnums.TypeArray')
      var Type = this.Type;
      var TypeArray = this.$t("PriceExtraEnums.TypeArray");
      if (TypeArray.length !== Object.keys(Type).length) {
        console.error(
          "TypeArray " +
            TypeArray.length +
            " is not same length as Type enum " +
            Object.keys(Type).length
        );
      }
      TypeArray.forEach((element) => {
        if (element.value != Type[element.value]) {
          console.error(
            "TypeArray contains item that is not in Type enum " + element.value
          );
        }
      });
      //reverse check Type vs TypeArray
      Object.keys(Type).forEach((element) => {
        //find if element is in TypeArray
        var found = TypeArray.find((item) => item.value === element);
        if (found === undefined) {
          console.error(
            "Type contains item that is not in TypeArray enum " + element
          );
        }
      });
    },

    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/pricelist/${this.pricelistId}/extra`
        )
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
          this.gridItemsClone = JSON.parse(JSON.stringify(this.gridItems));

          let containsJourney = this.gridItems.some(
            (item) => item.type === "PRICE_JOURNEY"
          );
          let containsWaiting = this.gridItems.some(
            (item) => item.type === "PRICE_WAITING"
          );
          if (containsWaiting === false || containsJourney === false) {
            this.disableCreateButton = true;
            this.disableCreateObligatoryButton = false;
            this.snackbar.show = "true";
            // "Vytvor najprv povinné položky (PRICE_WAITING a PRICE_JOURNEY)!";
            this.snackbar.message = this.$t(
              "PriceExtra.msgObligatoryPriceExtraWarning"
            );

            this.snackbar.color = "warning";
          } else {
            this.disableCreateButton = false;
            this.disableCreateObligatoryButton = true;
          }
          /*if (this.pricelistType === "SIMPLE") {
            // remove all types from FareType but "DEFAULT"
            this.FareType = this.FareType.filter((x) => x === "DEFAULT");
            // remove all types from FareChangeToDefault but "NONE"
            this.FareChangeToDefault = this.FareChangeToDefault.filter(
              (x) => x === "NONE"
            );
            this.FareJourneyMinimumType = this.FareJourneyMinimumType.filter(
              (x) => x === "DEFAULT"
            );
          }
          if (
            this.pricelistType === "SIMPLE" ||
            this.pricelistType === "LOCALITY"
          ) {
            // remove all types from FareStartType but "NONE"
            this.FareStartType = this.FareStartType.filter(
              (x) => x === "FIXED"
            );
          }*/
        })
        .catch((e) => {
          this.loadingData = false;
          console.log("Error", e);
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("PriceExtra.msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    openCreateDialog() {
      this.editItem = Object.assign({}, this.editDefaultItem);
      this.editDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }
    },
    openEditDialog(item) {
      this.editItem = Object.assign({}, item);
      this.editDialog = true;
    },
    openCreateObligatoryDialog() {
      this.editItem = Object.assign({}, this.editObligatoryItem);
      //check if this.gridItems includes "PRICE_JOURNEY"
      let containsJourney = this.gridItems.some(
        (item) => item.type === "PRICE_JOURNEY"
      );
      if (containsJourney) {
        //remove "PRICE_JOURNEY" key from the ObligatoryType object
        this.obligatoryTypeEnum = this.obligatoryTypeEnum.filter(function (
          ele
        ) {
          return ele != "PRICE_JOURNEY";
        });
      }
      //check if this.gridItems includes "PRICE_WAITING"
      let containsWaiting = this.gridItems.some(
        (item) => item.type === "PRICE_WAITING"
      );
      if (containsWaiting) {
        //remove "PRICE_WAITING" from the ObligatoryType enum
        this.obligatoryTypeEnum = this.obligatoryTypeEnum.filter(function (
          ele
        ) {
          return ele != "PRICE_WAITING";
        });
      }

      this.disabledObligatoryFields = false;
      this.editObligatoryDialog = true;
      if (this.$refs.formCreateObligatory) {
        this.$refs.formCreateObligatory.resetValidation();
      }
    },
    openEditObligatoryDialog(item) {
      this.editItem = Object.assign({}, item);
      this.disabledObligatoryFields = true;
      this.editObligatoryDialog = true;
    },
    submitEditDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        this.loadingSaveItem = true;
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/pricelist/${this.pricelistId}/extra`,
            this.editItem
          )
          .then((response) => {
            this.editDialog = false;

            this.snackbar.show = "true";
            //this.snackbar.message = "Item was saved";
            this.snackbar.message = this.$t("PriceExtra.msgSaveSuccess");
            this.snackbar.color = "success";

            this.loadingSaveItem = false;
            this.getAll();
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("PriceExtra.msgSaveError");
            this.snackbar.color = "error";

            this.loadingSaveItem = false;
          });
      }
    },
    submitEditObligatoryDialog() {
      const isValid = this.$refs.formCreateObligatory.validate();
      if (isValid) {
        this.loadingSaveItem = true;
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/pricelist/${this.pricelistId}/extra`,
            this.editItem
          )
          .then((response) => {
            this.editObligatoryDialog = false;

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("PriceExtra.msgSaveSuccess");
            this.snackbar.color = "success";

            this.loadingSaveItem = false;
            this.getAll();
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("PriceExtra.msgSaveError");
            this.snackbar.color = "error";

            this.loadingSaveItem = false;
          });
      }
    },
  },
};
</script>
