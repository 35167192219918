<template>
  <div id="map" ref="map"></div>
</template>
<style scoped>
#map {
  height: 40vh;
}
</style>
<script>
import axios from "axios";
export default {
  components: {
    //  Prices,
  },
  name: "ShapesGoogleMap",
  props: {
    passDataOnMap: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      errors: null,
      //Defaultne centrum zobrazenia stredu vsetkych map
      mapCenter: { lat: 48.148, lng: 18.127 },
      map: null,
      //Zoznam vygenerovanych objektov na mape
      localityShapes: [],
      localityNameMarkers: [],
      //bounds pre mapu
      localityListMapBounds: [],
    };
  },
  watch: {
    passDataOnMap: function () {
      //console.log(this.passDataOnMap);
      /*if (
        document.querySelector(
          "script[src*='https://maps.googleapis.com/maps/api/js']"
        )
      ) {
        console.log(
          "ShapesGoogleMap.vue - passDataOnMap - Google Maps API already loaded"
        );*/
      var tempData = this.passDataOnMap;
      this.displayShapesOnMap(tempData);
      //}
    },
  },
  //computed:{
  //formatDate() {
  //return moment(this.item.validFrom).local();
  //}
  //},
  created() {},
  mounted() {
    this.initMap();

    //Nacitanie Google Maps API ak nie je nacitane
    /*if (
      !document.querySelector(
        'script[src="https://maps.googleapis.com/maps/api/js"]'
      )
    ) {
      console.log("ShapesGoogleMap.vue -  Google Maps API not loaded, will be loaded...");
    } else {
      console.log("ShapesGoogleMap.vue -  Google Maps API already loaded");
      this.initMap();
    }*/
    //console.log("this.$parent", this.$parent);

    //Kontrola ci je nacitana kniznica google maps, ak nie počkať na jej nacitanie
    /* if(typeof google === 'undefined') {
      console.log("ShapesGoogleMap.vue -  Google Maps API not loaded, will be loaded...");
      this.$parent.$on("googleMapsLoaded", () => {
        console.log("googleMapsLoaded - ShapesGoogleMap.vue");
        this.initMap();
        var tempData = this.passDataOnMap;
        this.displayShapesOnMap(tempData);
      });
    } else {
      console.log("ShapesGoogleMap.vue -  Google Maps API already loaded");
      this.initMap();
    } */

    //Kontrola ci je nacitana kniznica google maps, ak nie počkať na jej nacitanie
    /*if (
      !document.querySelector(
        "script[src*='https://maps.googleapis.com/maps/api/js']"
      )
    ) {
      console.log(
        "ShapesGoogleMap.vue -  Google Maps API not loaded, will be loaded..."
      );
      this.$parent.$on("googleMapsLoaded", () => {
        console.log("googleMapsLoaded - ShapesGoogleMap.vue");
        this.initMap();
        var tempData = this.passDataOnMap;
        this.displayShapesOnMap(tempData);
      });
    } else {
      console.log("ShapesGoogleMap.vue -  Google Maps API already loaded");
      this.initMap();
    }*/
  },
  methods: {
    //OSM mapa https://codepen.io/ItsMeStevieG/pen/QRgjmL
    initMap() {
      //console.log("function: initMap");
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter, //the center of the map
        zoom: 8,
        maxZoom: 18,
        minZoom: 4,
        streetViewControl: false,
        fullscreenControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP, // typ mapy s cestami
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //umiestnenie prepínača typu mapy - dole v strede
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.HYBRID,
          ],
        },
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //BOTTOM_LEFT
        },
        scaleControl: false,
      });
      //neviem preco toto nejde
      /*var tempGeo;
      tempGeo = new google.maps.LatLng(48.678, 21.123);
      console.log("tempGeo3", tempGeo);
      */
      /*var tempGeo;
      tempGeo = Object.assign({}, new google.maps.LatLng(48.678, 21.123));
      console.log("tempGeo3", tempGeo);*/

      //this.displayShapesOnMap();
    },
    displayShapesOnMap(itemList) {
      var map = this.map;
      var bounds = new google.maps.LatLngBounds();
      //var tempGeo;
      //console.log("bounds init", bounds);
      var localityShapes = this.localityShapes;
      var localityNameMarkers = this.localityNameMarkers;
      //var itemList = this.gridItemsLocality;
      var colorName = eval(0); //Reset poradia farby
      //console.log("colorName", colorName);
      //console.log("itemList", itemList);
      //console.log("localityShapes.length", localityShapes.length);
      //Premazanie starých markerov
      for (var i = 0; i < localityShapes.length; i++) {
        localityShapes[i].setMap(null);
      }
      for (var i = 0; i < localityNameMarkers.length; i++) {
        localityNameMarkers[i].setMap(null);
      }
      //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
      if (itemList.constructor.name != "Array") {
        var newItemList = [];
        newItemList.push(itemList);
        itemList = newItemList;
      }
      //console.log("itemList.length 2", itemList.length);
      //console.log("itemList2 ", itemList);
      //Vytvorenie nových markerov
      for (var city in itemList) {
        colorName = colorName + eval(1);
        //console.log(colorName);
        //console.log(this.rangeColor(colorName));
        //console.log("city", city);
        //nadefinovanie, aby bola vysoká priesvitnosť objektu s pioritou -1000
        var fillOpacityIndex = 0.35;
        var strokeColorIndex = "#FFFF00";
        if (itemList[city].priority == "-1000") {
          fillOpacityIndex = 0.05;
        } else if (itemList[city].priority == "-999") {
          fillOpacityIndex = 0.12;
        } else {
          fillOpacityIndex = 0.35;
        }
        //Ak sa zobrazuju stanovistia, tak hlavne z nich zvyraznit cervenym obrysom
        if (itemList[city].defaultStand) {
          if (itemList[city].defaultStand == true) {
            strokeColorIndex = "#F00000";
          } else {
            strokeColorIndex = "#FFFF00";
          }
        }
        //console.log("city", city);
        var latitude;
        var longitude;
        var radius;
        if (itemList[city].type == "POLYGON") {
          var prePath = [];
          var postPath = [];
          var finalPath = [];
          prePath = JSON.parse(itemList[city].path);

          //console.log("Polygon pre " + prePath);
          //console.log(JSON.parse(itemList[city].id));
          for (var i = 0; i < prePath.length; i++) {
            postPath.push({
              lat: prePath[i][0],
              lng: prePath[i][1],
            });
            bounds.extend(new google.maps.LatLng(prePath[i][0], prePath[i][1]));
          }
          //var postPath = jsonData["locality_info"]["path"];
          var _len = postPath.length;

          //console.log("Polygon post" + postPath);
          //var postPath = "[";
          var i;
          //var finalPath;
          for (i = 0; i < _len; i++) {
            finalPath.push(postPath[i]);
          }

          //console.log("Polygon final" + finalPath);

          var addListenersOnPolygon = function (polygon) {
            google.maps.event.addListener(polygon, "click", function (event) {
              //this.snackbar.show = "true";
              //this.snackbar.message = circle.title;
              //this.snackbar.color = "success";
              alert(polygon.title);
            });
          };
          //			function createPolygon() {

          var locationPolygon = new google.maps.Polygon({
            //         paths: defaultPolygon,
            //			paths: $('#taxi-loc-path').val(),
            paths: finalPath,
            strokeColor: strokeColorIndex,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: this.rangeColor(colorName),
            fillOpacity: fillOpacityIndex,
            editable: false,
            draggable: false,
            title:
              "ID: " +
              itemList[city].id +
              " | Name: " +
              itemList[city].name +
              " | Priority: " +
              itemList[city].priority +
              " | Type: Polygon",
            zIndex: itemList[city].priority,
            idx: itemList[city].id,
            label: "B",
          });
          localityShapes.push(locationPolygon);
          locationPolygon.setMap(map);
          addListenersOnPolygon(locationPolygon);

          //				  }
          // Create Polygon
          //				setTimeout(createPolygon, 3000);
        } else {
          //console.log("city", city);
          var addListenersOnCircle = (circle) => {
            google.maps.event.addListener(circle, "click", function (event) {
              alert(circle.title);
              //this.snackbar.show = "true";
              //this.snackbar.message = circle.title;
              //this.snackbar.color = "success";
            });
          };

          latitude = itemList[city].latitude;
          longitude = itemList[city].longitude;
          radius = itemList[city].radius;
          //console.log("latitude",latitude,"longitude",longitude,"radius",radius);
          /*tempGeo = new google.maps.LatLng(
            parseFloat(latitude) + 11,
            parseFloat(longitude) + 21
          );
          console.log("tempGeo1", tempGeo);*/
          var cityCircle = new google.maps.Circle({
            editable: false,
            strokeColor: strokeColorIndex,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            //fillColor: '#FF'+(Math.round(city)+eval(10))+'00',
            fillColor: this.rangeColor(colorName),
            fillOpacity: fillOpacityIndex,
            zIndex: itemList[city].priority,
            map: map,
            //{"6":{"id":"6","name":"Trnava","lat":"48.37741","lng":"17.58723","radius":"5000","TAXISERVICES_id":"5"},"9":{"id":"9","name":"Tren\u010d\u00edn","lat":"48.888248","lng":"18.019179","radius":"10000","TAXISERVICES_id":"5"}}
            center: new google.maps.LatLng(latitude, longitude),
            //center: new google.maps.LatLng(48.878, 19.629),
            //center: tempGeo,
            //radius: parseInt(itemList[city].radius) //Math.sqrt(itemList[city].radius) * 100
            //radius: Math.sqrt(itemList[city].radius) * 100
            radius: parseInt(radius),
            title:
              "ID: " +
              itemList[city].id +
              " | Name: " +
              itemList[city].name +
              " | Priority: " +
              itemList[city].priority +
              " | Type: Circle",
            idx: itemList[city].id,
            label: "A",
          });
          //map.setCenter( new google.maps.LatLng(itemList[city].lat, itemList[city].lng));
          //map.setZoom(7);  // Why 16? Because it looks good.
          //console.log("cityCircle", cityCircle);
          localityShapes.push(cityCircle);
          cityCircle.setMap(map);
          addListenersOnCircle(cityCircle);
          //console.log("bounds0", bounds);
          //var tempGeo = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
          //var tempGeo = new google.maps.LatLng(48.37741, 17.37741);
          // console.log("tempGeo", tempGeo);
          //bounds.extend(tempGeo);
          //console.log("bounds1", bounds);
          //Toto je lep
          /* bounds.extend(
            new google.maps.LatLng(
              parseFloat(latitude) - parseFloat(radius / 100000),
              parseFloat(longitude) - parseFloat(radius / 100000)
            )
          );
          console.log("bounds2", bounds);
          bounds.extend(
            new google.maps.LatLng(
              parseFloat(latitude) + parseFloat(radius / 100000),
              parseFloat(longitude) + parseFloat(radius / 100000)
            )
          );*/
          if (itemList[city].priority) {
            //console.log("itemList[city].priority", itemList[city].priority);
            if (itemList[city].priority > -999) {
              bounds.extend({
                lat: parseFloat(latitude) - parseFloat(radius / 100000),
                lng: parseFloat(longitude) - parseFloat(radius / 100000),
              });
              //console.log("bounds2", bounds);
              bounds.extend({
                lat: parseFloat(latitude) + parseFloat(radius / 100000),
                lng: parseFloat(longitude) + parseFloat(radius / 100000),
              });
              //console.log("bounds3", bounds);
            } else {
              //console.log("Priorita u circle nizsia ako -998, circle sa nezobrazi");
            }
          } else {
            bounds.extend({
              lat: parseFloat(latitude) - parseFloat(radius / 100000),
              lng: parseFloat(longitude) - parseFloat(radius / 100000),
            });
            //console.log("bounds2", bounds);
            bounds.extend({
              lat: parseFloat(latitude) + parseFloat(radius / 100000),
              lng: parseFloat(longitude) + parseFloat(radius / 100000),
            });
            //console.log("bounds3", bounds);
          }
        }

        //Doplnenie textu nad polygony a kruhy
        //set text itemList[city].name on map where itemList[city].latitude and itemList[city].longitude
        if (true) {
          var tempLocation = new google.maps.LatLng(
            itemList[city].latitude,
            itemList[city].longitude
          );
          var svgMarker = {
            path: "M0 0 L1 0 L1 1 L0 1 Z",
            fillColor: "transparent",
            fillOpacity: 0,
            strokeColor: "transparent",
            strokeOpacity: 0,
            scale: 1,
            anchor: new google.maps.Point(0.5, 0.5),
          };
          var nameMarker = new google.maps.Marker({
            position: tempLocation,
            map: map,
            icon: svgMarker,
            label: {
              text: itemList[city].name,
              color: "red",
              fontSize: "12px",
              fontWeight: "bold",
            },
          });
          localityNameMarkers.push(nameMarker);
        }
        //zoom na všetky objekty

        //Attach click listener to marker
        //google.maps.event.addListener(cityCircle, 'radius_changed', (function(city) {
        //console.log(cityCircle.getRadius());
        //console.log(city.marker.getRadius());
        //console.log(cityCircle);
        //console.log(cityCircle.marker.getRadius());
        //console.log(cityCircle.marker);
        //})(city));
        //Remember loc in the `locations` so its info can be displayed and so its marker can be deleted.
        //localityShapes[key] = city;
      }
      //console.log("bounds final", bounds);
      //Ak nie je bounds definovany, tak sa nastavi default
      this.localityListMapBounds = bounds;
      //Idealne je aby sa to zoomovalo hned, ale v tom case to este nie je vyrenderovane
      //map.fitBounds(bounds);
      this.fitBoundsMap();
      //this.map.fitBounds(bounds);
      this.localityShapes = localityShapes;
      this.localityNameMarkers = localityNameMarkers;
      //console.log("localityShapes", localityShapes);
    },
    fitBoundsMap() {
      //spomalenie kvôli renderovania a vyzera to tak lepsie
      var map = this.map;
      var bounds = this.localityListMapBounds;

      var centerOfMap = this.getCookie("centerOfMap")
        ? JSON.parse(this.getCookie("centerOfMap"))
        : {};
      //ak sú bound definované, tak sa nastavia, ak nie, tak sa nastavi center mapy
      if (bounds) {
        //console.log("bounds final x", bounds);
        setTimeout(function () {
          map.fitBounds(bounds);
        }, 1000);
      } else {
        //console.log("centerOfMap", centerOfMap);
        map.setCenter(centerOfMap);
        map.setZoom(7);
        this.mapCenter = centerOfMap;
      }
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
    rangeColor(colorName) {
      switch (colorName) {
        case 1:
          return "#00FF00";
          break; //green
        case 2:
          return "#FFD700";
          break; //gold
        case 3:
          return "#996633";
          break; //
        case 4:
          return "#FF8C00";
          break; //
        case 5:
          return "#EE82EE";
          break; //violet
        case 6:
          return "#0000FF";
          break; //blue
        case 7:
          return "#00008B";
          break; //
        case 8:
          return "#ADD8E6";
          break; //
        case 9:
          return "#87CEEB";
          break; //
        case 10:
          return "#FF0000";
          break; //red
        case 11:
          return "#ffa500";
          break; //orange
        case 12:
          return "#800080";
          break; //purple
        case 13:
          return "#FFFF00";
          break; //yellow
        case 14:
          return "#C0C0C0";
          break; //silver
        case 15:
          return "#006400";
          break; //
        case 16:
          return "#90EE90";
          break; //
        case 17:
          return "#FF99CC";
          break; //
        case 18:
          return "#F5F5DC";
          break; //beige
        case 19:
          return "#696969";
          break; //
        case 20:
          return "#E52B50";
          break; //amaranth
        case 21:
          return "#FFBF00";
          break; //amber
        case 22:
          return "#007FFF";
          break; //azure
        case 23:
          return "#CD7F32";
          break; //bronze
        case 24:
          return "#DE3163";
          break; //cerise
        case 25:
          return "#7B3F00";
          break; //chocolate
        case 26:
          return "#0047AB";
          break; //cobalt blue
        case 27:
          return "#F88379";
          break; //coral
        case 28:
          return "#50C878";
          break; //emerald
        case 29:
          return "#4B0082";
          break; //indigo
        case 30:
          return "#00A86B";
          break; //jade
        case 31:
          return "#B57EDC";
          break; //lavender
        case 32:
          return "#BFFF00";
          break; //lime
        case 33:
          return "#FF00FF";
          break; //magenta
        case 34:
          return "#800000";
          break; //maroon
        case 35:
          return "#000080";
          break; //navy blue
        case 36:
          return "#FF2400";
          break; //scarlet
        case 37:
          return "#A7FC00";
          break; //spring bud
        case 38:
          return "#008080";
          break; //teal
        case 39:
          return "#FFC0CB";
          break; //pink
        case 40:
          return "#D1E231";
          break; //pear
        case 41:
          return "#00FF10";
          break; //green
        case 42:
          return "#FFD710";
          break; //gold
        case 43:
          return "#996643";
          break; //
        case 44:
          return "#FF8C10";
          break; //
        case 45:
          return "#EE82FE";
          break; //violet
        case 46:
          return "#0000EF";
          break; //blue
        case 47:
          return "#00009B";
          break; //
        case 48:
          return "#ADD8F6";
          break; //
        case 49:
          return "#87CEFB";
          break; //
        case 50:
          return "#FF0010";
          break; //red
        case 51:
          return "#ffa510";
          break; //orange
        case 52:
          return "#800090";
          break; //purple
        case 53:
          return "#FFFF10";
          break; //yellow
        case 54:
          return "#C0C0D0";
          break; //silver
        case 55:
          return "#006410";
          break; //
        case 56:
          return "#90EE80";
          break; //
        case 57:
          return "#FF99DC";
          break; //
        case 58:
          return "#F5F5EC";
          break; //beige
        case 59:
          return "#696979";
          break; //
        case 60:
          return "#E52B60";
          break; //amaranth
        case 61:
          return "#FFBF10";
          break; //amber
        case 62:
          return "#007FEF";
          break; //azure
        case 63:
          return "#CD7F42";
          break; //bronze
        case 64:
          return "#DE3173";
          break; //cerise
        case 65:
          return "#7B3F10";
          break; //chocolate
        case 66:
          return "#0047BB";
          break; //cobalt blue
        case 67:
          return "#F88389";
          break; //coral
        case 68:
          return "#50C878";
          break; //emerald
        case 69:
          return "#4B0082";
          break; //indigo
        case 70:
          return "#00A86B";
          break; //jade
        case 71:
          return "#B57EDC";
          break; //lavender
        case 72:
          return "#BFFF00";
          break; //lime
        case 73:
          return "#FF00FF";
          break; //magenta
        case 74:
          return "#800000";
          break; //maroon
        case 75:
          return "#000080";
          break; //navy blue
        case 76:
          return "#FF2400";
          break; //scarlet
        case 77:
          return "#A7FC00";
          break; //spring bud
        case 78:
          return "#008080";
          break; //teal
        case 79:
          return "#FFC0CB";
          break; //pink
        case 80:
          return "#D1E231";
          break; //pear
        default:
          return "#FF6600";
      }
    },
  },
};
</script>
